import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Spinner } from "reactstrap";

import Home from "../Home";
import { Modal, ModalBody } from "reactstrap";
import { validEmailRegex } from "../config/appRegex";

import Api from "../API/login";
import { ProductName, publicUrl } from "../config/appConstatnts";
import { toast } from "react-toastify";

const ForgotPasswordPage = (props) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [rMessage, setRMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(modal);

  const handelChange = (event) => {
    event.persist();

    let { value } = event.target;
    value = value.trim();

    setEmail(value);

    if (value.length === 0) {
      setEmailError("Email is required.");
    } else {
      setEmailError(validEmailRegex.test(value) ? "" : "Enter valid email.");
    }
  };

  const handelSubmit = (event) => {
    event.preventDefault();

    setRMessage("");

    if (email.length === 0) {
      setEmailError("Email is required.");
    } else if (!validEmailRegex.test(email)) {
      setEmailError("Enter valid email.");
    } else {
      setLoader(true);
      Api.forgotPassword(email)
        .then((res) => {
          toast.success(res.data.Message);
          setTimeout(() => redirectToSignin(), 1000);
        })
        .catch((error) => {
          setEmailError(error.response.data.Message);
        })
        .finally(() => setLoader(false));
    }
  };

  const [apiData, setApiData] = useState(null);
  let history = useHistory();
  const redirectToSignin = () => {
    history.push("/signin");
  };

  useEffect(() => {
    let data =
      JSON.parse(localStorage.getItem("CompanyDetails")) !== null ||
      JSON.parse(localStorage.getItem("CompanyDetails")) !== undefined
        ? JSON.parse(localStorage.getItem("CompanyDetails"))
        : ProductName;
    setApiData(data);
  }, []);

  return (
    <section className="common-section vh-0 login-comman-sec">
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered sign-up-modal">
        <ModalBody className="main-block signin-block">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0 forgot-password-row">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Forgot Password</h1>
                      <p className="mb-2">
                        Please enter your email and we will send you email to reset your password
                      </p>
                    </div>

                    <div className="err-message">{rMessage}</div>
                    <form onSubmit={handelSubmit}>
                      <div className="content">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="email"
                            name="email"
                            placeholder="Email Address*"
                            maxLength="100"
                            value={email}
                            onChange={handelChange}
                          />
                          <span className="input-icon1">
                            {apiData && (
                              <img
                                src={`${publicUrl}/${apiData.CompanyId}/images/envelope.svg`}
                                alt="views"
                                className="img-fluid"
                              />
                            )}
                          </span>
                        </div>
                        <div className="err-message">{emailError}</div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="submit"
                            className={`btn btn-outline-primary blue-btn-bg ${
                              loader ? "btn-loading-state" : ""
                            }`}
                          >
                            <div className="button-inner">
                              {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                              <span className="btn-text">Request Password Reset</span>
                            </div>
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <Link to={"/signin"} className="h5 f-600 pl-2">
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  {apiData && (
                    <img
                      src={`${publicUrl}/${apiData.CompanyId}/images/logo-white.svg`}
                      alt="Company Logo"
                      className="img-fluid logo-white"
                    />
                  )}
                  <h1>Welcome</h1>
                  <p>
                    Increase profits with our proven dental marketing strategy that focuses on
                    patient communications.
                  </p>

                  <div className="btn-block signup-white-btn">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignin}
                    >
                      sign in
                    </button>
                  </div>
                  <p>Remember your password?</p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Home />
    </section>
  );
};
export default ForgotPasswordPage;
