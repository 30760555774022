import React, { useState } from "react";
import { Link } from "react-router-dom";
import { publicUrl } from "../../config/appConstatnts";
import { Spinner } from "reactstrap";
import { validZipCodeRegex } from "../../config/appRegex";
import { validateForm } from "../../config/commonFun";
import useStateList from "../_helperFunctions/StateList";
const Step2 = ({ apiData, nextPage, postData, setPostData, previousPage }) => {
  const { stateList } = useStateList("US");
  const [loader, setLoader] = useState(false);
  const [addressModel, setAddressModel] = useState({
    ...postData.Address,
  });
  const [addressModelErrors, setAddressModelErrors] = useState({
    StreetAddress: "",
    City: "",
    State: "",
    ZipCode: "",
  });

  // ==================handle events ==================
  const handelAddressModelChange = (event) => {
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    setAddressModel((addressModel) => ({
      ...addressModel,
      [name]: value,
    }));

    switch (name) {
      case "StreetAddress":
        if (value.trim().length === 0) {
          errorValue = "Street Address is required.";
        }
        break;
      case "City":
        if (value.trim().length === 0) {
          errorValue = "City is required.";
        }
        break;
      case "State":
        if (value.trim().length === 0) {
          errorValue = "State is required.";
        }
        break;
      case "ZipCode":
        if (value.trim().length === 0) {
          errorValue = "Zip Code is required.";
        } else {
          errorValue = validZipCodeRegex.test(value) ? "" : "Enter valid Zip Code.";
        }
        break;
      default:
        break;
    }

    setAddressModelErrors((addressModelErrors) => ({
      ...addressModelErrors,
      [name]: errorValue,
    }));
  };

  const onThirdPage = (event) => {
    event.preventDefault();
    let flagError = false;
    if (addressModel.StreetAddress.length === 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        StreetAddress: "Street Address is required.",
      }));
      flagError = true;
    }
    if (addressModel.City.length === 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        City: "City is required.",
      }));
      flagError = true;
    }
    if (addressModel.State == null || addressModel.State.length === 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        State: "State is required.",
      }));
      flagError = true;
    }
    if (addressModel.ZipCode.length === 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ZipCode: "Zip Code is required.",
      }));
      flagError = true;
    } else if (!validZipCodeRegex.test(addressModel.ZipCode)) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ZipCode: "Enter valid Zip Code.",
      }));
      flagError = true;
    }

    if (validateForm(addressModelErrors) && !flagError) {
      const newData = {
        ...postData,
        Address: {
          LocationName: addressModel.StreetAddress,
          StreetAddress: addressModel.StreetAddress,
          City: addressModel.City,
          State: addressModel.State,
          ZipCode: addressModel.ZipCode,
        },
      };
      setPostData(newData);
      nextPage();
    }
  };

  return (
    <>
      <div className="inner-block text-center sign-up">
        <div className="block-1">
          <h1>
            Sign up to <span>{apiData && <b>{apiData.CompanyName}</b>}</span>
          </h1>
          <p className="mb-2">Enter your Practice Address</p>
        </div>
        <form>
          <div className="content">
            <div className="form-group">
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/Street.svg`}
                    alt="views"
                    className="img-fluid"
                  />
                )}
              </span>
              <input
                type="text"
                className="form-control light-border"
                id="streetAddress"
                placeholder="Street Address*"
                name="StreetAddress"
                value={addressModel.StreetAddress}
                onChange={handelAddressModelChange}
              />
            </div>
            <div className="err-message">{addressModelErrors.StreetAddress}</div>
            <div className="form-group">
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/city.svg`}
                    alt="views"
                    className="img-fluid"
                  />
                )}
              </span>
              <input
                type="text"
                className="form-control light-border"
                id="city"
                placeholder="City*"
                name="City"
                value={addressModel.City}
                onChange={handelAddressModelChange}
              />
            </div>
            <div className="err-message">{addressModelErrors.City}</div>
            <div className="form-group select-form-group">
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/State.svg`}
                    alt="views"
                    className="img-fluid"
                  />
                )}
              </span>
              <select
                className="form-control"
                name="State"
                value={addressModel.State}
                onChange={handelAddressModelChange}
              >
                {stateList.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="err-message">{addressModelErrors.State}</div>
            <div className="form-group">
              <span className="input-icon1">
                {apiData && (
                  <img
                    src={`${publicUrl}/${apiData.CompanyId}/images/zip-code.svg`}
                    alt="views"
                    className="img-fluid"
                  />
                )}
              </span>
              <input
                type="text"
                className="form-control light-border"
                id="zipCode"
                placeholder="Zip Code*"
                name="ZipCode"
                value={addressModel.ZipCode}
                onChange={handelAddressModelChange}
              />
            </div>
            <div className="err-message">{addressModelErrors.ZipCode}</div>
          </div>
          <div className="account-btn-block">
            <div className="btn-block">
              <button
                type="button"
                className={`btn btn-outline-primary blue-btn-bg ${
                  loader ? "btn-loading-state" : ""
                }`}
                onClick={onThirdPage}
              >
                <div className="button-inner">
                  {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                  <span className="btn-text">next</span>
                </div>
              </button>
            </div>
          </div>

          <div className="account-btn-block text-center pt-2">
            <Link className="back-button mr-auto pl-2" onClick={previousPage}>
              <i class="fa fa-chevron-left"></i>
              Back
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step2;
