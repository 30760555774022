import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Home from "../Home";
import { Modal, ModalBody } from "reactstrap";
import { publicUrl } from "../config/appConstatnts";

// export default function ChangePassPage(){
const ChangePassPage = (props) => {
  let history = useHistory();
  const redirectToSignin = () => {
    history.push("/signin");
  };
  const [eyeTog1, seteyeTog1] = useState("false");
  const handleToggleeyeTog1 = () => {
    seteyeTog1(!eyeTog1);
  };
  const [eyeTog2, seteyeTog2] = useState("false");
  const handleToggleeyeTog2 = () => {
    seteyeTog2(!eyeTog2);
  };
  const [eyeTog3, seteyeTog3] = useState("false");
  const handleToggleeyeTog3 = () => {
    seteyeTog3(!eyeTog3);
  };
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(modal);

  return (
    <section className="common-section vh-0">
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="modal-dialog-centered sign-up-modal"
      >
        <ModalBody className="main-block signin-block">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Change Password</h1>
                      <p className="mb-2">
                        {" "}
                        Your new password must be different from old passwords.
                      </p>
                    </div>

                    <form>
                      <div className="content">
                        <div className="form-group">
                          <span className="input-icon1">
                            {JSON.parse(
                              localStorage.getItem("CompanyDetails")
                            ) && (
                              <img
                                src={`${publicUrl}/${
                                  JSON.parse(
                                    localStorage.getItem("CompanyDetails")
                                  ).CompanyId
                                }/images/icon-2.png`}
                                alt="views"
                                className="img-fluid"
                              />
                            )}
                          </span>
                          <input
                            type="password"
                            className="form-control light-border pass-input"
                            id="exampleInputPassword1"
                            placeholder="Old Password*"
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${
                                !eyeTog1 ? "passVisible" : ""
                              }`}
                              onClick={handleToggleeyeTog1}
                            ></i>
                          </span>
                        </div>
                        <div className="form-group">
                          <span className="input-icon1">
                            {JSON.parse(
                              localStorage.getItem("CompanyDetails")
                            ) && (
                              <img
                                src={`${publicUrl}/${
                                  JSON.parse(
                                    localStorage.getItem("CompanyDetails")
                                  ).CompanyId
                                }/images/icon-2.png`}
                                alt="views"
                                className="img-fluid"
                              />
                            )}
                          </span>
                          <input
                            type="password"
                            className="form-control light-border pass-input"
                            id="exampleInputPassword1"
                            placeholder="New Password*"
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${
                                !eyeTog2 ? "passVisible" : ""
                              }`}
                              onClick={handleToggleeyeTog2}
                            ></i>
                          </span>
                        </div>
                        <div className="form-group">
                          <span className="input-icon1">
                            {JSON.parse(
                              localStorage.getItem("CompanyDetails")
                            ) && (
                              <img
                                src={`${publicUrl}/${
                                  JSON.parse(
                                    localStorage.getItem("CompanyDetails")
                                  ).CompanyId
                                }/images/icon-2.png`}
                                alt="views"
                                className="img-fluid"
                              />
                            )}
                          </span>
                          <input
                            type="password"
                            className="form-control light-border pass-input"
                            id="exampleInputPassword1"
                            placeholder="Confirm New Password*"
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${
                                !eyeTog3 ? "passVisible" : ""
                              }`}
                              onClick={handleToggleeyeTog3}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="button"
                            className="btn btn-outline-primary   blue-btn-bg "
                          >
                            Save
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <Link to="/signin" className="h5 f-600 pl-2">
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  {JSON.parse(localStorage.getItem("CompanyDetails")) && (
                    <img
                      src={`${publicUrl}/${
                        JSON.parse(localStorage.getItem("CompanyDetails"))
                          .CompanyId
                      }/images/logo-white.svg`}
                      alt="CompanyLogo-white"
                      className="img-fluid logo-white"
                    />
                  )}
                  <h1>Welcome</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <div className="btn-block">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignin}
                    >
                      sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Home />
    </section>
  );
};
export default ChangePassPage;
