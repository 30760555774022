import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";

class Api {
  // API for get Doctors
  static getProvider = async (AccountId) => {
    return axios.post(
      API_URL + "/report/LoadDoctors?uuid=" + new Date().toISOString(),
      {
        AccountId: AccountId,
      },
      HEADER
      // { params: { uuid: new Date().toISOString() } }
    );
  };

  static getDashboardSummary = async (model) => {
    return axios.post(
      API_URL + "/report/LoadDashboardSummary?uuid=" + new Date().toISOString(),
      model,
      HEADER
      // { params: { uuid: new Date().toISOString() } }
    );
  };

  static getWidgetSections = async () => {
    return axios.get(
      API_URL + "/report/GetWidgetSections?uuid=" + new Date().toISOString(),
      HEADER
    );
  };

  static getProductionChart = async (model) => {
    return await axios.post(API_URL + "/report/LoadProductionChart", model, HEADER);
  };

  static getWidgetsDetails = async (model) => {
    return axios.post(
      API_URL + "/report/WidgetsDetails?uuid=" + new Date().toISOString(),
      model,
      HEADER
      // { params: { uuid: new Date().toISOString() } }
    );
  };

  static getPatientChart = async (model) => {
    return axios.post(
      API_URL + "/report/LoadPatientChart?uuid=" + new Date().toISOString(),
      model,
      HEADER
      // { params: { uuid: new Date().toISOString() } }
    );
  };
  static postGoal = (model) => {
    return axios.post(API_URL + "/report/AddEditProductionGoal", model, HEADER);
  };

  static downloadDashboardSummary = (data) => {
    return axios.post(API_URL + "/report/DownloadDashboardSummary", data, HEADER);
  };
}

export default Api;
