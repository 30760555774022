import history from "../history";
// Check is error or not
export const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

// Redirect to forgot password
export const gotoForgotPassword = () => {
  history.push("/forgotpassword");
};
// Redirect to sign up
export const gotoSignUp = () => {
  history.push("/signup");
};
// Redirect to dashboard
export const gotoDashboard = () => {
  history.push("/dashboard");
};

// redirect to signup
export const goToSignin = () => {
  history.push("/signin");
};

export const capitalizedWord = (CompanyName) =>
  CompanyName.charAt(0).toUpperCase() + CompanyName.slice(1);

export const checkNumber = (value) => {
  const numberPattern = /\d+/g;
  if (value && value.length > 0) {
    value.match(numberPattern);
    return Number(value.match(numberPattern)[0]);
  } else {
    return 0;
  }
};
