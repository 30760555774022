import React, { useEffect, useState } from "react";
import AppNavbar from "./common-components/AppNavbar";
import Chart from "react-apexcharts";
import Card1 from "../src/assets/images/bar-chart.png";
import Card2 from "../src/assets/images/totalcollection.svg";
import Card3 from "../src/assets/images/user.png";
import Card4 from "../src/assets/images/new-patients.svg";

import Goal from "./models/Goal";

import AppSectionLoader from "./common-components/AppSectionLoader";

import Api from "./API/dashboard";
import {
  localStorageSectionList,
  localStorageUserData,
  ProductName,
  publicUrl,
} from "./config/appConstatnts";
import axios from "axios";
import CustomeDatePicker from "./DateSection/CustomeDatePicker";
import AppPageLoader from "./common-components/AppPageLoader";
import DashboardModel from "./models/DashboardModel";

function Dashboard_historical() {
  const [appPageLoader, setAppPageLoader] = useState(false);
  const [theme, setTheme] = useState({
    ThemeColor: "",
    chartColor: { Doctor: "", Hygiene: "" },
  });

  const [provider, setProvider] = useState({
    DoctorId: 0,
    Salutation: "",
    FirstName: "",
    LastName: "",
    FullName: "",
  });

  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const [dashboardSummaryModel, setDashboardSummaryModel] = useState({
    AccountId: 0,
    UserId: 0,
    ChartType: 3,
    StartDate: "",
    EndDate: "",
  });

  const dummySummaryData = {
    TotalProduction: {
      value: "$0",
      imageUrl: "",
      title: "Total Production",
      tooltip: "Total Production",
      isRowData: false,
      module: 1,
    },
    TotalCollections: {
      value: "$0",
      imageUrl: "",
      title: "Total Collections",
      tooltip: "Total Collections",
      isRowData: false,
      module: 2,
    },
    UnscheduledActivePatients: {
      value: 0,
      imageUrl: "",
      title: "Unscheduled Active Patients",
      tooltip: "Unscheduled Active Patients",
      isRowData: false,
      module: 3,
    },
    NewPatients: {
      value: 0,
      imageUrl: "",
      title: "New Patients",
      tooltip: "New Patients",
      isRowData: false,
      module: 4,
    },
    ActivePatientsWidget: {
      value: 0,
      imageUrl: "",
      title: "Active Patients",
      tooltip: "Active Patients",
      isRowData: false,
      module: 5,
    },
    InActivePatientsWidget: {
      value: 0,
      imageUrl: "",
      title: "InActive Patients",
      tooltip: "InActive Patients",
      isRowData: false,
      module: 6,
    },
  };

  const [dashboardSummaryResModel, setDashboardSummaryResModel] = useState(dummySummaryData);

  const [dashboardSections, setDashboardSections] = useState({
    doctor: null,
    hygiene: null,
    frontOffice: null,
    referralSource: null,
  });

  const dummyDoctor = {
    section1: {
      title: "Doctor Production",
      value: "$0",
      dailyGoal: {
        title: "Yearly Goal",
        value: "$0",
        icon: "",
        iconClass: "",
      },
      aboveGoal: {
        title: "Above Goal",
        value: "$0 (0%)",
        icon: "arrow-up",
        iconClass: "green-txt",
      },
      previousWTD: {
        title: "From Previous WTD",
        value: "$0 (0%)",
        icon: "arrow-up",
        iconClass: "green-txt",
      },
      chart: {
        title: "Total Visits",
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: ["No Data"],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
      },
    },
    section2: {
      title: "Treatment Acceptance",
      value: "0",
      dailyGoal: {
        title: "Yearly Goal",
        value: "0.00%",
        icon: "",
        iconClass: "",
      },
      aboveGoal: {
        title: "Below Goal",
        value: "$0 (0.00%)",
        icon: "arrow-down",
        iconClass: "red-txt",
      },
      previousWTD: {
        title: "From Previous WTD",
        value: "$0 (0%)",
        icon: "arrow-up",
        iconClass: "green-txt",
      },
      chart: {
        title: null,
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: ["No Data"],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
            value: {},
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
        doctorList: [],
      },
    },
    section3: {
      title: "Production Per Visit",
      value: "$0",
      dailyGoal: {
        title: "Yearly Goal",
        value: "$0",
        icon: "",
        iconClass: "",
      },
      aboveGoal: {
        title: "Above Goal",
        value: "$0 (0%)",
        icon: "arrow-up",
        iconClass: "green-txt",
      },
      previousWTD: {
        title: "From Previous WTD",
        value: "$0 (0%)",
        icon: "arrow-up",
        iconClass: "green-txt",
      },
    },
  };
  const [doctorSections, setDoctorSections] = useState(dummyDoctor);

  const dummyHygiene = {
    section1: {
      title: "Hygiene Production",
      value: "$0",
      dailyGoal: {
        title: "Daily Goal",
        value: "$0",
        icon: "",
        iconClass: "",
      },
      aboveGoal: {
        title: "Above Goal",
        value: "$0",
        icon: "arrow-up",
        iconClass: "green-txt",
      },
      previousWTD: {
        title: "From Previous WTD",
        value: "0",
        icon: "arrow-down",
        iconClass: "red-txt",
      },
      chart: {
        title: "Total Visits",
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: ["No Data"],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
      },
    },
    section2: {
      title: "Reappointment Rate",
      value: "0",
      dailyGoal: {
        title: "Goal",
        value: "0",
        icon: "",
        iconClass: "",
      },
      aboveGoal: {
        title: "Above Goal",
        value: "0",
        icon: "arrow-up",
        iconClass: "green-txt",
      },
      previousWTD: {
        title: "From Previous WTD",
        value: "0",
        icon: "arrow-down",
        iconClass: "red-txt",
      },
      chart: {
        title: null,
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: ["No Data"],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
        doctorList: [],
      },
    },
    section3: {
      title: "Production Per Visit",
      value: "0",
      dailyGoal: {
        title: "Goal",
        value: "0",
        icon: "",
        iconClass: "",
      },
      aboveGoal: {
        title: "Below Goal",
        value: "0",
        icon: "arrow-up",
        iconClass: "green-txt",
      },
      previousWTD: {
        title: "From Previous WTD",
        value: "0",
        icon: "arrow-down",
        iconClass: "red-txt",
      },
    },
  };
  const [hygieneSections, setHygieneSections] = useState(dummyHygiene);

  const dummyFrontOffice = {
    section1: {
      title: "Reactivated Patients",
      value: "0",
      dailyGoal: {
        title: "Yearly Goal",
        value: "0.00%",
        icon: "",
        iconClass: "",
      },
      aboveGoal: {
        title: "Below Goal",
        value: "$0 (0.00%)",
        icon: "arrow-down",
        iconClass: "red-txt",
      },
      scheduleGoal: {
        title: "Scheduled Production",
        tooltip: "Scheduled Producution",
        value: "$0",
        icon: "",
        iconClass: "",
      },
      chart: {
        title: null,
        options: [],
        series: [],
      },
    },
    section2: {
      title: "Patients Not Scheduled",
      value: "0",
      unscheduledProd: {
        title: "Unscheduled Production",
        value: "$0",
        icon: "",
        iconClass: "",
      },
      chart: {
        title: null,
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: ["No Data"],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
        doctorList: [],
      },
    },
    section3: {
      title: "Total Unscheduled Active Patients",
      value: "0",
      patients: {
        title: "Value",
        value: "$0",
        icon: "",
        iconClass: "",
      },
      chart: {
        title: null,
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: ["No Data"],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
      },
    },
  };
  const [frontOfficeSections, setFrontOfficeSections] = useState(dummyFrontOffice);

  const dummyReferal = {
    section1: {
      chart: {
        title: null,
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: [],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
        doctorList: [],
      },
    },
    section2: {
      chart: {
        title: null,
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: [],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
        doctorList: [],
      },
    },
  };
  const [referralSourceSections, setReferralSourceSections] = useState(dummyReferal);

  const [selectedGoal, setSelectedGoal] = useState({
    widgetId: 0,
    title: "",
    goalDetails: {},
  });
  const [widgetId, setWidgetId] = useState(0);
  const [GoalShow, SetGoal] = React.useState(false);
  const openGoal = (data) => {
    let obj = selectedGoal;
    setWidgetId(data.id);
    obj.widgetId = data.id;
    obj.title = data.title;
    obj.goalDetails = data.goalDetails;
    setSelectedGoal(obj);
    SetGoal(!GoalShow);
  };

  const [showDatePicker, setShowDatePicker] = useState(false);

  const chartColors = [
    { colorClass: "yellow-label", value: `${theme.ThemeColor}` },
    { colorClass: "blue-label", value: "#004f71" },
    { colorClass: "limeblue-label", value: "#59cbe8" },
    { colorClass: "green-label", value: "#ff0000" },
  ];

  const [showDashboardSummaryLoading, setShowDashboardSummaryLoading] = useState(true);
  const [showPatientChartLoading, setShowPatientChartLoading] = useState(true);
  const [showDoctorSectionLoading, setShowDoctorSectionLoading] = useState(true);
  const [showHygieneSectionLoading, setShowHygieneSectionLoading] = useState(true);
  const [showFrontOfficeSectionLoading, setShowFrontOfficeSectionLoading] = useState(true);
  const [showReferralSourceSectionLoading, setShowReferralSourceSectionLoading] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [toDate, setToDate] = useState("");
  const [toDateError, setToDateError] = useState("");

  const executeSelectedOption = (event) => {
    event.preventDefault();
    if (event.target.value != null || event.target.value !== "") {
      setChartType(event.target.value);
    }
  };

  const showAllLoaders = () => {
    setShowDashboardSummaryLoading(true);
    setShowPatientChartLoading(true);
    setShowDoctorSectionLoading(true);
    setShowHygieneSectionLoading(true);
    setShowFrontOfficeSectionLoading(true);
  };

  const [month, setMonth] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [hygienes, setHygienes] = useState([]);

  const getPatientChart = (data) => {
    setShowPatientChartLoading(true);
    Api.getProductionChart(data)
      .then((res) => {
        setShowPatientChartLoading(false);
        let result = res.data.Result;
        let cate = [];
        let doctor = [];
        let hygienes = [];
        for (let i = 0; i < result.length; i++) {
          cate.push(result[i].LabelName);
          doctor.push(result[i].Value[0].RoundValue);
          hygienes.push(result[i].Value[1].RoundValue);
        }
        setMonth(cate);
        setDoctor(doctor);
        setHygienes(hygienes);
      })
      .catch((error) => {
        setShowPatientChartLoading(false);
      });
  };

  const [DashboardModal, setDashboardModal] = React.useState(false);
  const [selectedWidget, setSelectedWidget] = useState({
    title: "",
    tooltip: "",
    isRowData: false,
    value: "$0",
    imageUrl: "",
    module: 1,
  });
  const detailPopUp = (selectedCard) => {
    setDashboardModal(!DashboardModal);
    setSelectedWidget(selectedCard);
  };

  const getDoctorWidgetSections = (data) => {
    setShowDoctorSectionLoading(true);
    setDoctorSections(dummyDoctor);
    Api.getWidgetsDetails(data)
      .then((response) => {
        setShowDoctorSectionLoading(false);
        if (response.data && response.data.Result && response.data.Result.length > 0) {
          let doctorSection = {
            section1: null,
            section2: null,
            section3: null,
          };
          for (let i = 0; i < response.data.Result.length; i++) {
            const element = response.data.Result[i];
            if (element.OrderBy === 1) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 4) {
                  data.chart.title = widgetBox.Title;
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  let totalSum = 0;
                  for (let i = 0; i < widgetBox.Value.length; i++) {
                    const item = widgetBox.Value[i];
                    const colorCode = chartColors[i % 4];

                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value));
                    data.chart.options.labels.push(item.DoctorName);
                  }
                  if (totalSum === 0) {
                    data.chart.series = [];
                  }
                }
              }
              doctorSection.section1 = data;
            } else if (element.OrderBy === 2) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 4) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return "$" + (Math.round(val * 100) / 100).toLocaleString();
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  "$" +
                                  (
                                    Math.round(
                                      w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b;
                                      }, 0) * 100
                                    ) / 100
                                  ).toLocaleString()
                                );
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                      value: {
                        formatter: function (value) {
                          return "$" + value;
                        },
                      },
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  let totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 4];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value));
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li
                        className={`blue-label ${colorCode.colorClass}`}
                        key={`${item.DoctorName}`}
                      >
                        {item.DoctorName}
                        <span>{item.StringValue}</span>
                      </li>
                    );
                  }
                  if (totalSum === 0) {
                    data.chart.series = [];
                  }
                }
              }
              doctorSection.section2 = data;
            } else if (element.OrderBy === 3) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
              }
              doctorSection.section3 = data;
            }
          }
          setDoctorSections(doctorSection);
        }
      })
      .catch((e) => {
        setShowDoctorSectionLoading(false);
      });
  };
  const getHygieneWidgetSections = (data) => {
    setShowHygieneSectionLoading(true);
    setHygieneSections(dummyHygiene);

    Api.getWidgetsDetails(data)
      .then((response) => {
        setShowHygieneSectionLoading(false);
        if (response.data && response.data.Result && response.data.Result.length > 0) {
          let hygieneSection = {
            section1: null,
            section2: null,
            section3: null,
          };
          for (let i = 0; i < response.data.Result.length; i++) {
            const element = response.data.Result[i];
            if (element.OrderBy === 1) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 4) {
                  data.chart.title = widgetBox.Title;
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  let totalSum = 0;
                  for (let i = 0; i < widgetBox.Value.length; i++) {
                    const item = widgetBox.Value[i];
                    const colorCode = chartColors[i % 4];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value));
                    data.chart.options.labels.push(item.DoctorName);
                  }
                  if (totalSum === 0) {
                    data.chart.series = [];
                  }
                }
              }
              hygieneSection.section1 = data;
            } else if (element.OrderBy === 2) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 4) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  let totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 4];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value));
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li
                        className={`blue-label ${colorCode.colorClass}`}
                        key={`${item.DoctorName}`}
                      >
                        {item.DoctorName}
                        <span>{item.StringValue}</span>
                      </li>
                    );
                  }
                  if (totalSum === 0) {
                    data.chart.series = [];
                  }
                }
              }
              hygieneSection.section2 = data;
            } else if (element.OrderBy === 3) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
              }
              hygieneSection.section3 = data;
            }
          }
          setHygieneSections(hygieneSection);
        }
      })
      .catch((e) => {
        setShowHygieneSectionLoading(false);
      });
  };
  const getFrontOfficeWidgetSections = (data) => {
    setShowFrontOfficeSectionLoading(true);
    setFrontOfficeSections(dummyFrontOffice);

    Api.getWidgetsDetails(data)
      .then((response) => {
        setShowFrontOfficeSectionLoading(false);
        if (response.data && response.data.Result && response.data.Result.length > 0) {
          let frontOffice = {
            section1: null,
            section2: null,
            section3: null,
          };
          for (let i = 0; i < response.data.Result.length; i++) {
            const element = response.data.Result[i];
            if (element.OrderBy === 1) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                scheduleGoal: {
                  title: null,
                  tooltip: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.title = widgetBox.Tooltip;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (
                    <>
                      {widgetBox.Value}
                      <br />({per})
                    </>
                  );
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 2) {
                  data.scheduleGoal.title = widgetBox.Title;
                  data.scheduleGoal.tooltip = widgetBox.Tooltip;
                  data.scheduleGoal.value = widgetBox.Value;
                  data.scheduleGoal.icon = widgetBox.IconClass;
                  data.scheduleGoal.iconClass = widgetBox.IconColorClass;
                }
              }
              frontOffice.section1 = data;
            } else if (element.OrderBy === 2) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                unscheduledProd: {
                  title: null,
                  tooltip: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.unscheduledProd.title = widgetBox.Title;
                  data.unscheduledProd.tooltip = widgetBox.Tooltip;
                  data.unscheduledProd.value = widgetBox.Value;
                  data.unscheduledProd.icon = widgetBox.IconClass;
                  data.unscheduledProd.iconClass = widgetBox.IconColorClass;
                }

                if (widgetBox.OrderBy === 2) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 4];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value));
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li
                        className={`blue-label ${colorCode.colorClass}`}
                        key={`${item.DoctorName}`}
                      >
                        {item.DoctorName}
                        <span>{item.StringValue}</span>
                      </li>
                    );
                  }
                  if (totalSum === 0) {
                    data.chart.series = [];
                  }
                }
              }
              frontOffice.section2 = data;
            } else if (element.OrderBy === 3) {
              let data = {
                id: element.Id,
                goalDetails: element.GoalDetails,
                title: element.Title,
                tooltip: element.Tooltip,
                value: element.Value,
                patients: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.patients.title = widgetBox.Title;
                  data.patients.tooltip = widgetBox.Tooltip;
                  data.patients.value = widgetBox.Value;
                  data.patients.icon = widgetBox.IconClass;
                  data.patients.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy === 2) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  let totalSum = 0;
                  for (let i = 0; i < widgetBox.Value.length; i++) {
                    const item = widgetBox.Value[i];
                    const colorCode = chartColors[i % 4];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value));
                    data.chart.options.labels.push(item.DoctorName);
                  }
                  if (totalSum === 0) {
                    data.chart.series = [];
                  }
                }
              }
              frontOffice.section3 = data;
            }
          }
          setFrontOfficeSections(frontOffice);
        }
      })
      .catch((e) => {
        setShowFrontOfficeSectionLoading(false);
      });
  };
  const getReferralSourceWidgetSections = (data) => {
    setShowReferralSourceSectionLoading(true);
    setReferralSourceSections(dummyReferal);

    Api.getWidgetsDetails(data)
      .then((response) => {
        setShowReferralSourceSectionLoading(false);
        if (response.data && response.data.Result && response.data.Result.length > 0) {
          let referralSource = {
            section1: null,
            section2: null,
          };
          for (let i = 0; i < response.data.Result.length; i++) {
            const element = response.data.Result[i];
            if (element.OrderBy === 1) {
              let data = {
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  let totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 4];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value));
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li className={colorCode.colorClass}>{item.DoctorName}</li>
                    );
                  }
                  if (totalSum === 0) {
                    data.chart.series = [];
                  }
                }
              }
              referralSource.section1 = data;
            } else if (element.OrderBy === 2) {
              let data = {
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy === 1) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return "$" + (Math.round(val * 100) / 100).toLocaleString();
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  "$" +
                                  (
                                    Math.round(
                                      w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b;
                                      }, 0) * 100
                                    ) / 100
                                  ).toLocaleString()
                                );
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  let totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 4];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value));
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li className={colorCode.colorClass}>{item.DoctorName}</li>
                    );
                  }
                  if (totalSum === 0) {
                    data.chart.series = [];
                  }
                }
              }
              referralSource.section2 = data;
            }
          }
          setReferralSourceSections(referralSource);
        }
      })
      .catch((e) => {
        setShowReferralSourceSectionLoading(false);
      });
  };

  const getWidgetSectionsList = (dataObj) => {
    Api.getWidgetSections()
      .then((response) => {
        setShowReferralSourceSectionLoading(false);
        var myData = {
          doctor: null,
          hygiene: null,
          frontOffice: null,
          referralSource: null,
        };

        for (let index = 0; index < response.data.Result.length; index++) {
          const element = response.data.Result[index];
          switch (element.OrderBy) {
            case 1:
              myData.doctor = element.Id;
              break;
            case 2:
              myData.hygiene = element.Id;
              break;
            case 3:
              myData.frontOffice = element.Id;
              break;
            case 4:
              myData.referralSource = element.Id;
              break;
            default:
              break;
          }
        }
        localStorage.setItem("sectionList", JSON.stringify(myData));

        setDashboardSections(myData);
        setShowDoctorSectionLoading(false);
      })
      .catch((error) => {
        setShowDoctorSectionLoading(false);
        setShowHygieneSectionLoading(false);
        setShowFrontOfficeSectionLoading(false);
        setShowReferralSourceSectionLoading(false);
      });
  };

  const getWidgetSections = (data) => {
    getDoctorWidgetSections({ ...data, SectionId: dashboardSections.doctor });
    getHygieneWidgetSections({
      ...data,
      SectionId: dashboardSections.hygiene,
    });
    getFrontOfficeWidgetSections({
      ...data,
      SectionId: dashboardSections.frontOffice,
    });
    getReferralSourceWidgetSections({
      ...data,
      SectionId: dashboardSections.referralSource,
    });
  };

  const setChartType = (id) => {
    let obj = { ...dashboardSummaryModel };
    obj.ChartType = id === "8" ? null : id;
    obj.AccountId = user.AccountId;
    obj.UserId = obj.UserId === 0 ? null : obj.UserId;
    if (id !== "8") {
      showAllLoaders();
      getDashboardSummary(obj);
      getWidgetSections(obj);
      getPatientChart(obj);
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
    setDashboardSummaryModel(obj);
  };

  const handelApplyDateFilter = (event) => {
    event.preventDefault();
    let obj = { ...dashboardSummaryModel };
    obj.ChartType = null;
    obj.StartDate = null;
    obj.EndDate = null;
    obj.AccountId = user.AccountId;
    if (fromDate) {
      obj.StartDate = new Date(fromDate).toISOString();
    } else {
      setFromDateError("From Date is required.");
    }

    if (toDate) {
      obj.EndDate = new Date(toDate).toISOString();
    } else {
      setToDateError("To Date is required.");
    }

    if (obj.StartDate && obj.EndDate) {
      if (new Date(fromDate) > new Date(toDate)) {
        setFromDateError("Please select less date then To Date");
      } else if (new Date(toDate) < new Date(fromDate)) {
        setToDateError("Please select greater date then From Date.");
      } else {
        showAllLoaders();
        getDashboardSummary(obj);
        getWidgetSections(obj);
        getPatientChart(obj);
      }
    }
    setDashboardSummaryModel(obj);
  };

  const setUserId = (id) => {
    let obj = { ...dashboardSummaryModel };
    obj.AccountId = user.AccountId;
    obj.UserId = id === 0 ? null : id;
    setDashboardSummaryModel(obj);
    showAllLoaders();
    getDashboardSummary(obj);
    getWidgetSections(obj);
    getPatientChart(obj);
  };

  const getDashboardSummary = (model) => {
    if (model.AccountId > 0) {
      setWidgetId(0);
      setShowDashboardSummaryLoading(true);
      setDashboardSummaryResModel(dummySummaryData);
      Api.getDashboardSummary(model)
        .then((res) => {
          setShowDashboardSummaryLoading(false);
          let newData = { ...dummySummaryData };
          for (let index = 0; index < res.data.Result.length; index++) {
            const item = res.data.Result[index];

            if (item.Value) {
              item.Value = item.Value.split(".")[0];
            }
            switch (item.SectionId) {
              case 14:
                newData.TotalCollections = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                  tooltip: item.ToolTip,
                  isRowData: item.IsRowData,
                  module: 2,
                };
                break;
              case 13:
                newData.TotalProduction = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                  tooltip: item.ToolTip,
                  isRowData: item.IsRowData,
                  module: 1,
                };
                break;
              case 8:
                newData.UnscheduledActivePatients = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                  tooltip: item.ToolTip,
                  isRowData: item.IsRowData,
                  module: 3,
                };
                break;
              case 15:
                newData.NewPatients = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                  tooltip: item.ToolTip,
                  isRowData: item.IsRowData,
                  module: 4,
                };
                break;
              case 32:
                newData.ActivePatientsWidget = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                  tooltip: item.ToolTip,
                  isRowData: item.IsRowData,
                  module: 5,
                };
                break;
              case 33:
                newData.InActivePatientsWidget = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                  tooltip: item.ToolTip,
                  isRowData: item.IsRowData,
                  module: 6,
                };
                break;
              default:
                break;
            }
          }
          setDashboardSummaryResModel(newData);
        })
        .catch((error) => {
          setShowDashboardSummaryLoading(false);
        });
    } else {
      setShowDashboardSummaryLoading(false);
    }
  };

  const handelDateChange = (event) => {
    event.preventDefault();
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    setFromDateError("");
    setToDateError("");
    switch (name) {
      case "fromDate":
        if (value.length === 0) {
          errorValue = "From Date is required.";
        } else if (toDate !== "" && new Date(value) > new Date(toDate)) {
          errorValue = "Please select less date then To Date";
        } else {
          errorValue = "";
        }
        setFromDate(value);
        setFromDateError(errorValue);
        break;
      case "toDate":
        if (value.length === 0) {
          errorValue = "To Date is required.";
        } else if (fromDate !== "" && new Date(value) < new Date(fromDate)) {
          errorValue = "Please select greater date then From Date";
        } else {
          errorValue = "";
        }
        setToDate(value);
        setToDateError(errorValue);
        break;
      default:
        break;
    }
  };

  const reload = () => {
    getDashboardSummary(dashboardSummaryModel);
    getPatientChart(dashboardSummaryModel);
    getWidgetSections(dashboardSummaryModel);
  };

  const getProvider = (AccountId) => {
    if (AccountId > 0) {
      let obj = { ...dashboardSummaryModel };
      obj.AccountId = user.AccountId;
      obj.UserId = null;
      Api.getProvider(AccountId)
        .then((res) => {
          setProvider(res.data.Result);
          getDashboardSummary(obj);
          getPatientChart(obj);
          let sectionList = localStorageSectionList;
          if (sectionList) {
            var myData = {
              doctor: null,
              hygiene: null,
              frontOffice: null,
              referralSource: null,
            };
            myData = { ...myData, ...sectionList };

            setDashboardSections(myData);
            setShowDoctorSectionLoading(false);
          } else {
            setShowDoctorSectionLoading(false);
            setTimeout(() => {
              getWidgetSectionsList(obj);
            }, 1000);
          }
        })
        .catch((error) => {
          getDashboardSummary(obj);
          getPatientChart(obj);
          let sectionList = JSON.parse(localStorage.getItem("sectionList"));
          if (sectionList) {
            var myData = {
              doctor: null,
              hygiene: null,
              frontOffice: null,
              referralSource: null,
            };
            myData = { ...myData, ...sectionList };

            setDashboardSections(myData);
            setShowDoctorSectionLoading(false);
          } else {
            setTimeout(() => {
              getWidgetSectionsList(obj);
              setShowDoctorSectionLoading(false);
            }, 1000);
          }
        });
    }
  };

  const PatientsDetailMixChart = (cat, docData, HyData, title) => {
    return {
      series: [
        {
          name: "Doctor",
          type: "bar",
          data: docData,
        },
        {
          name: "Hygiene",
          type: "bar",
          data: HyData,
        },
      ],

      options: {
        chart: {
          type: "bar",
          height: 345,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: [`${theme.chartColor.Doctor}`, `${theme.chartColor.Hygiene}`],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },

        xaxis: {
          categories: cat,
          labels: {
            formatter: function (val) {
              return val;
            },
          },
          title: {
            text: title,
            offsetX: 0,
            offsetY: 50,
          },
          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          title: {
            text: "Production",
            offsetX: 5,
            offsetY: 0,
          },
          axisBorder: {
            show: true,
            color: " #c8c8c8",
            offsetX: 0,
            offsetY: 0,
          },
          label: {
            offsetX: 20,
            offsetY: 100,
            rotate: 0,
          },
        },

        tooltip: {
          enabled: true,
          marker: {
            show: false,
          },

          y: {
            show: false,
          },
          x: {
            show: false,
          },
          style: {
            fontSize: "12px",
          },
        },
        fill: {
          opacity: 1,
          colors: [`${theme.chartColor.Doctor}`, `${theme.chartColor.Hygiene}`],
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetX: 40,
        },
      },
    };
  };
  const state4 = {
    series: [
      {
        name: "Doctor",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Hygiene ",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 345,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [`${theme.chartColor.Doctor}`, `${theme.chartColor.Hygiene}`],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },

      xaxis: {
        categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
        title: {
          text: "Days",
        },
        axisBorder: {
          show: false,
        },

        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: "Production",
        },
        axisBorder: {
          show: true,
          color: " #c8c8c8",
          offsetX: 0,
          offsetY: 0,
        },
      },

      tooltip: {
        enabled: true,
        marker: {
          show: false,
        },

        y: {
          show: false,
        },
        x: {
          show: false,
        },
        style: {
          fontSize: "12px",
        },
      },
      fill: {
        opacity: 1,
        colors: [`${theme.chartColor.Doctor}`, `${theme.chartColor.Hygiene}`],
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetX: 40,
      },
    },
  };

  useEffect(() => {
    if (
      localStorageUserData != null &&
      localStorageUserData.UserId &&
      localStorageUserData.AccountId > 0
    ) {
      setUser(localStorageUserData);
    }
    if (user.AccountId > 0) {
      showAllLoaders();
      getProvider(user.AccountId);
      let obj = { ...dashboardSummaryModel };
      obj.AccountId = user.AccountId;
      obj.UserId = null;
      setDashboardSummaryModel(obj);
    }
  }, [user.AccountId]);

  useEffect(() => {
    if (
      dashboardSections.doctor != null ||
      dashboardSections.hygiene != null ||
      dashboardSections.frontOffice != null ||
      dashboardSections.referralSource != null
    ) {
      let obj = { ...dashboardSummaryModel };
      obj.AccountId = user.AccountId;
      obj.UserId = null;
      getWidgetSections(obj);
    }
  }, [dashboardSections]);

  useEffect(() => {
    const companyId = localStorage.getItem("CompanyId");

    axios
      .get(`${publicUrl}/${companyId}/js/themeConstants.json`)
      .then((res) => {
        setTheme({
          ThemeColor: res.data.ThemeColor,
          chartColor: {
            Doctor: res.data.chartColor.Doctor,
            Hygiene: res.data.chartColor.Hygiene,
          },
        });
      })
      .catch((err) => {
        setTheme({
          ThemeColor: "#f87c56",
          chartColor: {
            Doctor: "#898d90",
            Hygiene: "#898D90",
          },
        });
      });
  }, []);

  return (
    <div>
      <AppNavbar
        provider={provider}
        setUserId={setUserId}
        setChartType={setChartType}
        dropDownChange={executeSelectedOption}
        ChartType={dashboardSummaryModel.ChartType}
      />
      <div id="sub-header ">
        {showDatePicker ? (
          <CustomeDatePicker
            fromDate={fromDate}
            toDate={toDate}
            fromDateError={fromDateError}
            toDateError={toDateError}
            handelDateChange={handelDateChange}
            handelApplyDateFilter={handelApplyDateFilter}
          />
        ) : (
          <div className="d-flex py-4"></div>
        )}
      </div>
      <div id="blue-card" className="calender-box pt-0">
        <div className="container-fluid">
          <div className="row calender-row">
            <div className="col-xl-5 col-md-12 col-12 padding-r-4 calender-col">
              <div className="row">
                <div className=" col-lg-6  col-md-6 col-sm-6 col-12 card-padding-1 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      <h6 onClick={() => detailPopUp(dashboardSummaryResModel.TotalProduction)}>
                        {dashboardSummaryResModel.TotalProduction.title}
                        <img
                          alt=""
                          src={
                            ProductName &&
                            `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                          }
                          className="info-icon1"
                          title={dashboardSummaryResModel.TotalProduction.tooltip}
                        />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>{dashboardSummaryResModel.TotalProduction.value}</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img alt="" src={Card1} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      <h6 onClick={() => detailPopUp(dashboardSummaryResModel.TotalCollections)}>
                        {dashboardSummaryResModel.TotalCollections.title}
                        <img
                          alt=""
                          src={
                            ProductName &&
                            `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                          }
                          className="info-icon1"
                          title={dashboardSummaryResModel.TotalCollections.tooltip}
                        />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>{dashboardSummaryResModel.TotalCollections.value}</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img alt="" src={Card2} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-1 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      <h6
                        onClick={() =>
                          detailPopUp(dashboardSummaryResModel.UnscheduledActivePatients)
                        }
                      >
                        {dashboardSummaryResModel.UnscheduledActivePatients.title}
                        <img
                          alt=""
                          src={
                            ProductName &&
                            `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                          }
                          className="info-icon1"
                          title={dashboardSummaryResModel.UnscheduledActivePatients.tooltip}
                        />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>{dashboardSummaryResModel.UnscheduledActivePatients.value}</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img alt="" src={Card4} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      <h6 onClick={() => detailPopUp(dashboardSummaryResModel.NewPatients)}>
                        {dashboardSummaryResModel.NewPatients.title}
                        <img
                          alt=""
                          src={
                            ProductName &&
                            `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                          }
                          className="info-icon1"
                          title={dashboardSummaryResModel.NewPatients.tooltip}
                        />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>{dashboardSummaryResModel.NewPatients.value}</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img alt="" src={Card3} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-1 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      <h6
                        onClick={() => detailPopUp(dashboardSummaryResModel.ActivePatientsWidget)}
                      >
                        {dashboardSummaryResModel.ActivePatientsWidget.title}
                        <img
                          alt=""
                          src={
                            ProductName &&
                            `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                          }
                          className="info-icon1"
                          title={dashboardSummaryResModel.ActivePatientsWidget.tooltip}
                        />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>{dashboardSummaryResModel.ActivePatientsWidget.value}</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img alt="" src={Card3} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      <h6
                        onClick={() => detailPopUp(dashboardSummaryResModel.InActivePatientsWidget)}
                      >
                        {dashboardSummaryResModel.InActivePatientsWidget.title}
                        <img
                          alt=""
                          src={
                            ProductName &&
                            `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                          }
                          className="info-icon1"
                          title={dashboardSummaryResModel.InActivePatientsWidget.tooltip}
                        />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>{dashboardSummaryResModel.InActivePatientsWidget.value}</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img alt="" src={Card3} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={!showDashboardSummaryLoading ? "d-none" : ""}>
                <AppSectionLoader />
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-12 padding-l-4 calender-col prod-col">
              <div className="white-card">
                <h6 className="blue-title-h6 position-absolute">Production</h6>
                <Chart
                  id="#chart-stack"
                  options={
                    month.length > 0
                      ? PatientsDetailMixChart(month, doctor, hygienes).options
                      : state4.options
                  }
                  series={
                    month.length > 0
                      ? PatientsDetailMixChart(month, doctor, hygienes).series
                      : state4.series
                  }
                  type="bar"
                  height={450}
                />
              </div>
              <div className={!showPatientChartLoading ? "d-none" : ""}>
                <AppSectionLoader />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- cards --> */}
      {/* Doctor */}
      <div id="chart-data" className="historical-doctor card-row doctor-row pb-0 circle-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">Doctor</h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row">
                <div className="doctor-production patients-info-box donut-chart-col">
                  {doctorSections && doctorSections.section1 && (
                    <>
                      <div className="patients-info">
                        <h6 onClick={() => detailPopUp(doctorSections.section1)}>
                          {doctorSections.section1.title}
                          <img
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={doctorSections.section1.tooltip}
                            alt=""
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">{doctorSections.section1.value}</span>
                            <div onClick={() => openGoal(doctorSections.section1)}>
                              <div className="daily-goal right-border">
                                <h6>
                                  {doctorSections.section1.dailyGoal.title
                                    ? doctorSections.section1.dailyGoal.title
                                    : ""}
                                </h6>
                                <span className="number">
                                  {doctorSections.section1.dailyGoal.value
                                    ? doctorSections.section1.dailyGoal.value
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${
                                  doctorSections.section1.previousWTD.iconClass
                                    ? doctorSections.section1.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    doctorSections.section1.previousWTD.icon
                                      ? doctorSections.section1.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  doctorSections.section1.previousWTD.iconClass
                                    ? doctorSections.section1.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section1.previousWTD.value
                                  ? doctorSections.section1.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                {doctorSections.section1.previousWTD.title
                                  ? doctorSections.section1.previousWTD.title
                                  : ""}
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${
                                  doctorSections.section1.aboveGoal.iconClass
                                    ? doctorSections.section1.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    doctorSections.section1.aboveGoal.icon
                                      ? doctorSections.section1.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  doctorSections.section1.aboveGoal.iconClass
                                    ? doctorSections.section1.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section1.aboveGoal.value
                                  ? doctorSections.section1.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${
                                  doctorSections.section1.aboveGoal.iconClass
                                    ? doctorSections.section1.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section1.aboveGoal.title
                                  ? doctorSections.section1.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        <h6 className="m-0">Total Visits</h6>
                        <div className="patients-info-map donut-center-value">
                          <div className="app-chart-container">
                            <p className="chart-data">
                              <span>439</span>
                            </p>
                            <div className="inner-container">
                              {doctorSections.section1.chart.series.length > 0 ? (
                                <Chart
                                  id="new-5"
                                  options={doctorSections.section1.chart.options}
                                  series={doctorSections.section1.chart.series}
                                  type="donut"
                                  height={240}
                                />
                              ) : (
                                <span className="my-auto">0</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box treatment-info-box">
                  {doctorSections && doctorSections.section2 && (
                    <>
                      <div className="patients-info right-border">
                        <h6 onClick={() => detailPopUp(doctorSections.section2)}>
                          {doctorSections.section2.title}
                          <img
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={doctorSections.section2.tooltip}
                            alt=""
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">{doctorSections.section2.value}</span>

                            <div onClick={() => openGoal(doctorSections.section2)}>
                              <div className="daily-goal  right-border">
                                <h6>
                                  {doctorSections.section2.dailyGoal.title
                                    ? doctorSections.section2.dailyGoal.title
                                    : ""}
                                </h6>
                                <span className="number">
                                  {doctorSections.section2.dailyGoal.value
                                    ? doctorSections.section2.dailyGoal.value
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${
                                  doctorSections.section2.previousWTD.iconClass
                                    ? doctorSections.section2.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    doctorSections.section2.previousWTD.icon
                                      ? doctorSections.section2.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  doctorSections.section2.previousWTD.iconClass
                                    ? doctorSections.section2.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section2.previousWTD.value
                                  ? doctorSections.section2.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                <span className="status-txt">
                                  {doctorSections.section2.previousWTD.title
                                    ? doctorSections.section2.previousWTD.title
                                    : ""}
                                </span>
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${
                                  doctorSections.section2.aboveGoal.iconClass
                                    ? doctorSections.section2.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    doctorSections.section2.aboveGoal.icon
                                      ? doctorSections.section2.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  doctorSections.section2.aboveGoal.iconClass
                                    ? doctorSections.section2.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section2.aboveGoal.value
                                  ? doctorSections.section2.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${
                                  doctorSections.section2.aboveGoal.iconClass
                                    ? doctorSections.section2.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section2.aboveGoal.title
                                  ? doctorSections.section2.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        <h6 className="m-0">Treatments</h6>
                        <div className="patients-info-map">
                          <div className="flex-fill width-100 pos-relative inner-col">
                            <ul className="donut-label list-unstyled">
                              {doctorSections.section2.chart.doctorList}
                            </ul>
                            <div className="app-chart-container">
                              <div className="inner-container">
                                {doctorSections.section2.chart.series.length > 0 ? (
                                  <Chart
                                    id="#donut-chart3"
                                    className="donut mt-0"
                                    options={doctorSections.section2.chart.options}
                                    series={doctorSections.section2.chart.series}
                                    type="donut"
                                    height={240}
                                  />
                                ) : (
                                  <span className="my-auto">0</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box no-map">
                  {doctorSections && doctorSections.section3 && (
                    <>
                      <div className="patients-info">
                        <h6 onClick={() => detailPopUp(doctorSections.section3)}>
                          {doctorSections.section3.title ? doctorSections.section3.title : ""}

                          <img
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={doctorSections.section3.tooltip}
                            alt=""
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {doctorSections.section3.value ? doctorSections.section3.value : ""}
                            </span>
                            <div onClick={() => openGoal(doctorSections.section3)}>
                              <div className="daily-goal  right-border">
                                <h6>
                                  {doctorSections.section3.dailyGoal.title
                                    ? doctorSections.section3.dailyGoal.title
                                    : ""}
                                </h6>
                                <span className="number">
                                  {doctorSections.section3.dailyGoal.value
                                    ? doctorSections.section3.dailyGoal.value
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${
                                  doctorSections.section3.previousWTD.iconClass
                                    ? doctorSections.section3.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    doctorSections.section3.previousWTD.icon
                                      ? doctorSections.section3.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  doctorSections.section3.previousWTD.iconClass
                                    ? doctorSections.section3.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section3.previousWTD.value
                                  ? doctorSections.section3.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                <span className="status-txt">
                                  {doctorSections.section3.previousWTD.title
                                    ? doctorSections.section3.previousWTD.title
                                    : ""}
                                </span>
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${
                                  doctorSections.section3.aboveGoal.iconClass
                                    ? doctorSections.section3.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    doctorSections.section3.aboveGoal.icon
                                      ? doctorSections.section3.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  doctorSections.section3.aboveGoal.iconClass
                                    ? doctorSections.section3.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section3.aboveGoal.value
                                  ? doctorSections.section3.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${
                                  doctorSections.section3.aboveGoal.iconClass
                                    ? doctorSections.section3.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {doctorSections.section3.aboveGoal.title
                                  ? doctorSections.section3.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={!showDoctorSectionLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Hygiene */}
      <div id="chart-data" className="historical-doctor hygiene-row card-row pb-0 circle-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">Hygiene</h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row">
                <div className="doctor-production patients-info-box donut-chart-col">
                  {hygieneSections && hygieneSections.section1 && (
                    <>
                      <div className="patients-info">
                        <h6 onClick={() => detailPopUp(hygieneSections.section1)}>
                          {hygieneSections.section1.title}
                          <img
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={hygieneSections.section1.tooltip}
                            alt=""
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">{hygieneSections.section1.value}</span>
                            <div onClick={() => openGoal(hygieneSections.section1)}>
                              <div className="daily-goal right-border">
                                <h6>
                                  {hygieneSections.section1.dailyGoal.title
                                    ? hygieneSections.section1.dailyGoal.title
                                    : ""}
                                </h6>
                                <span className="number">
                                  {hygieneSections.section1.dailyGoal.value
                                    ? hygieneSections.section1.dailyGoal.value
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${
                                  hygieneSections.section1.previousWTD.iconClass
                                    ? hygieneSections.section1.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section1.previousWTD.icon
                                      ? hygieneSections.section1.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  hygieneSections.section1.previousWTD.iconClass
                                    ? hygieneSections.section1.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section1.previousWTD.value
                                  ? hygieneSections.section1.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                {hygieneSections.section1.previousWTD.title
                                  ? hygieneSections.section1.previousWTD.title
                                  : ""}
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${
                                  hygieneSections.section1.aboveGoal.iconClass
                                    ? hygieneSections.section1.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section1.aboveGoal.icon
                                      ? hygieneSections.section1.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  hygieneSections.section1.aboveGoal.iconClass
                                    ? hygieneSections.section1.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section1.aboveGoal.value
                                  ? hygieneSections.section1.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${
                                  hygieneSections.section1.aboveGoal.iconClass
                                    ? hygieneSections.section1.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section1.aboveGoal.title
                                  ? hygieneSections.section1.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        <h6 className="m-0">Total Visits</h6>
                        <div className="patients-info-map donut-center-value">
                          <div className="app-chart-container">
                            <p className="chart-data">
                              <span>439</span>
                            </p>
                            <div className="inner-container">
                              {hygieneSections.section1.chart.series.length > 0 ? (
                                <Chart
                                  id="new-5"
                                  options={hygieneSections.section1.chart.options}
                                  series={hygieneSections.section1.chart.series}
                                  type="donut"
                                  height={240}
                                />
                              ) : (
                                <span className="my-auto">0</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box">
                  {hygieneSections && hygieneSections.section2 && (
                    <>
                      <div className="patients-info right-border">
                        <h6 onClick={() => detailPopUp(hygieneSections.section2)}>
                          {hygieneSections.section2.title}
                          <img
                            alt=""
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={hygieneSections.section2.tooltip}
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">{hygieneSections.section2.value}</span>
                            <div onClick={() => openGoal(hygieneSections.section2)}>
                              <div className="daily-goal  right-border">
                                <h6>
                                  {hygieneSections.section2.dailyGoal.title
                                    ? hygieneSections.section2.dailyGoal.title
                                    : ""}
                                </h6>
                                <span className="number">
                                  {hygieneSections.section2.dailyGoal.value
                                    ? hygieneSections.section2.dailyGoal.value
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${
                                  hygieneSections.section2.previousWTD.iconClass
                                    ? hygieneSections.section2.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section2.previousWTD.icon
                                      ? hygieneSections.section2.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  hygieneSections.section2.previousWTD.iconClass
                                    ? hygieneSections.section2.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section2.previousWTD.value
                                  ? hygieneSections.section2.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                <span className="status-txt">
                                  {hygieneSections.section2.previousWTD.title
                                    ? hygieneSections.section2.previousWTD.title
                                    : ""}
                                </span>
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${
                                  hygieneSections.section2.aboveGoal.iconClass
                                    ? hygieneSections.section2.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section2.aboveGoal.icon
                                      ? hygieneSections.section2.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  hygieneSections.section2.aboveGoal.iconClass
                                    ? hygieneSections.section2.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section2.aboveGoal.value
                                  ? hygieneSections.section2.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${
                                  hygieneSections.section2.aboveGoal.iconClass
                                    ? hygieneSections.section2.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section2.aboveGoal.title
                                  ? hygieneSections.section2.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        <h6>Reappointments</h6>
                        <div className="patients-info-map">
                          <div className="flex-fill width-100 pos-relative inner-col">
                            <ul className="donut-label list-unstyled">
                              {hygieneSections.section2.chart.doctorList}
                            </ul>
                            <div className="app-chart-container">
                              <div className="inner-container">
                                {hygieneSections.section2.chart.series.length > 0 ? (
                                  <Chart
                                    id="#donut-chart3"
                                    className="donut mt-0"
                                    options={hygieneSections.section2.chart.options}
                                    series={hygieneSections.section2.chart.series}
                                    type="donut"
                                    height={240}
                                  />
                                ) : (
                                  <span className="my-auto">0</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box no-map">
                  {hygieneSections && hygieneSections.section3 && (
                    <>
                      <div className="patients-info">
                        <h6 onClick={() => detailPopUp(hygieneSections.section3)}>
                          {hygieneSections.section3.title ? hygieneSections.section3.title : ""}

                          <img
                            alt=""
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={hygieneSections.section3.tooltip}
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {hygieneSections.section3.value ? hygieneSections.section3.value : ""}
                            </span>
                            <div onClick={() => openGoal(hygieneSections.section3)}>
                              <div className="daily-goal  right-border">
                                <h6>
                                  {hygieneSections.section3.dailyGoal.title
                                    ? hygieneSections.section3.dailyGoal.title
                                    : ""}
                                </h6>
                                <span className="number">
                                  {hygieneSections.section3.dailyGoal.value
                                    ? hygieneSections.section3.dailyGoal.value
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${
                                  hygieneSections.section3.previousWTD.iconClass
                                    ? hygieneSections.section3.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section3.previousWTD.icon
                                      ? hygieneSections.section3.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  hygieneSections.section3.previousWTD.iconClass
                                    ? hygieneSections.section3.previousWTD.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section3.previousWTD.value
                                  ? hygieneSections.section3.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                <span className="status-txt">
                                  {hygieneSections.section3.previousWTD.title
                                    ? hygieneSections.section3.previousWTD.title
                                    : ""}
                                </span>
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${
                                  hygieneSections.section3.aboveGoal.iconClass
                                    ? hygieneSections.section3.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section3.aboveGoal.icon
                                      ? hygieneSections.section3.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${
                                  hygieneSections.section3.aboveGoal.iconClass
                                    ? hygieneSections.section3.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section3.aboveGoal.value
                                  ? hygieneSections.section3.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${
                                  hygieneSections.section3.aboveGoal.iconClass
                                    ? hygieneSections.section3.aboveGoal.iconClass
                                    : ""
                                }`}
                              >
                                {hygieneSections.section3.aboveGoal.title
                                  ? hygieneSections.section3.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={!showHygieneSectionLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Hygiene */}

      {/* Font Office */}
      <div id="chart-data" className="historical-doctor font-office-row card-row pb-0 circle-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">Front Office</h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row ">
                <div className="doctor-production patients-info-box reactivate-patients-box">
                  {frontOfficeSections && frontOfficeSections.section1 && (
                    <>
                      <div className="patients-info">
                        <h6 onClick={() => detailPopUp(frontOfficeSections.section1)}>
                          {frontOfficeSections.section1.title
                            ? frontOfficeSections.section1.title
                            : ""}
                          <img
                            alt=""
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={frontOfficeSections.section1.tooltip}
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {frontOfficeSections.section1.value
                                ? frontOfficeSections.section1.value
                                : ""}
                            </span>

                            <div onClick={() => openGoal(frontOfficeSections.section1)}>
                              <div className="daily-goal right-border">
                                <h6>
                                  {frontOfficeSections.section1.dailyGoal.title
                                    ? frontOfficeSections.section1.dailyGoal.title
                                    : ""}
                                </h6>
                                <span className="number">
                                  {frontOfficeSections.section1.dailyGoal.value
                                    ? frontOfficeSections.section1.dailyGoal.value
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-info scheduled-production-info">
                        <h6 onClick={() => detailPopUp(frontOfficeSections.section1.scheduleGoal)}>
                          {frontOfficeSections.section1.scheduleGoal.title
                            ? frontOfficeSections.section1.scheduleGoal.title
                            : ""}
                          <img
                            alt=""
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={frontOfficeSections.section1.scheduleGoal.tooltip}
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {frontOfficeSections.section1.scheduleGoal.value
                                ? frontOfficeSections.section1.scheduleGoal.value
                                : ""}
                            </span>
                            <div className="status-col">
                              <div className="next-status">
                                <span
                                  className={`icon ${
                                    frontOfficeSections.section1.aboveGoal.iconClass
                                      ? frontOfficeSections.section1.aboveGoal.iconClass
                                      : ""
                                  }`}
                                >
                                  <span
                                    className={
                                      frontOfficeSections.section1.aboveGoal.icon
                                        ? frontOfficeSections.section1.aboveGoal.icon
                                        : ""
                                    }
                                  ></span>
                                </span>
                                <span
                                  className={`status-value ${
                                    frontOfficeSections.section1.aboveGoal.iconClass
                                      ? frontOfficeSections.section1.aboveGoal.iconClass
                                      : ""
                                  }`}
                                >
                                  {frontOfficeSections.section1.aboveGoal.value
                                    ? frontOfficeSections.section1.aboveGoal.value
                                    : ""}
                                </span>
                                <span
                                  className={`status-txt ${
                                    frontOfficeSections.section1.aboveGoal.iconClass
                                      ? frontOfficeSections.section1.aboveGoal.iconClass
                                      : ""
                                  }`}
                                >
                                  {frontOfficeSections.section1.aboveGoal.title
                                    ? frontOfficeSections.section1.aboveGoal.title
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box not-scheduled-box">
                  {frontOfficeSections && frontOfficeSections.section2 && (
                    <>
                      <div className="patients-info right-border">
                        <h6 onClick={() => detailPopUp(frontOfficeSections.section2)}>
                          {frontOfficeSections.section2.title}
                          <img
                            alt=""
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={frontOfficeSections.section2.tooltip}
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">{frontOfficeSections.section2.value}</span>
                            <div className="daily-goal">
                              <h6
                                onClick={() =>
                                  detailPopUp(frontOfficeSections.section2.unscheduledProd)
                                }
                              >
                                {frontOfficeSections.section2.unscheduledProd.title
                                  ? frontOfficeSections.section2.unscheduledProd.title
                                  : ""}

                                <img
                                  alt=""
                                  src={
                                    ProductName &&
                                    `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                                  }
                                  className="info-icon1"
                                  title={frontOfficeSections.section2.unscheduledProd.tooltip}
                                />
                              </h6>
                              <span
                                className={`number ${
                                  frontOfficeSections.section2.unscheduledProd.iconClass
                                    ? frontOfficeSections.section2.unscheduledProd.iconClass
                                    : ""
                                }`}
                              >
                                {frontOfficeSections.section2.unscheduledProd.value
                                  ? frontOfficeSections.section2.unscheduledProd.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        <h6>Unscheduled</h6>
                        <div className="patients-info-map">
                          <div className="flex-fill width-100 pos-relative inner-col">
                            <ul className="donut-label list-unstyled">
                              {frontOfficeSections.section2.chart.doctorList}
                            </ul>
                            <div className="app-chart-container">
                              <div className="inner-container">
                                {frontOfficeSections.section2.chart.series.length > 0 ? (
                                  <Chart
                                    id="#donut-chart3"
                                    className="donut mt-0"
                                    options={frontOfficeSections.section2.chart.options}
                                    series={frontOfficeSections.section2.chart.series}
                                    type="donut"
                                    height={240}
                                  />
                                ) : (
                                  <span className="my-auto">0</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* </div> */}
                </div>
                <div className="doctor-production patients-info-box no-map unactive-patients">
                  {frontOfficeSections && frontOfficeSections.section3 && (
                    <>
                      <div className="patients-info">
                        <h6 onClick={() => detailPopUp(frontOfficeSections.section3)}>
                          {frontOfficeSections.section3.title
                            ? frontOfficeSections.section3.title
                            : ""}

                          <img
                            alt=""
                            src={
                              ProductName &&
                              `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                            }
                            className="info-icon1"
                            title={frontOfficeSections.section3.tooltip}
                          />
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col right-border">
                            <span className="number">
                              {frontOfficeSections.section3.value
                                ? frontOfficeSections.section3.value
                                : ""}
                            </span>
                            <div className="daily-goal ">
                              <h6
                                onClick={() => detailPopUp(frontOfficeSections.section3.patients)}
                              >
                                {frontOfficeSections.section3.patients.title
                                  ? frontOfficeSections.section3.patients.title
                                  : ""}

                                <img
                                  alt=""
                                  src={
                                    ProductName &&
                                    `${publicUrl}/${ProductName.CompanyId}/images/information.svg`
                                  }
                                  className="info-icon1"
                                  title={frontOfficeSections.section3.patients.tooltip}
                                />
                              </h6>
                              <span className="number">
                                {frontOfficeSections.section3.patients.value
                                  ? frontOfficeSections.section3.patients.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="patients-map">
                            <div className="patients-info-map  donut-center-value">
                              <div className="app-chart-container">
                                <p className="chart-data">
                                  <span>439</span>
                                </p>
                                <div className="inner-container">
                                  {frontOfficeSections.section3.chart.series.length > 0 ? (
                                    <Chart
                                      id="new-5"
                                      options={frontOfficeSections.section3.chart.options}
                                      series={frontOfficeSections.section3.chart.series}
                                      type="donut"
                                      height={240}
                                    />
                                  ) : (
                                    <span className="my-auto">0</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={!showFrontOfficeSectionLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Font Office */}

      {/* <!-- Footer Chart Row --> */}
      <div
        id="chart-data"
        className="historical-doctor pb-0 circle-chart footer-chart-section mb-5"
      >
        <div className="container-fluid">
          <div className="row footer-chart-row">
            <div className="paients-chart-col referral-chart">
              <div className="">
                <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                  New Patients by Referral Source
                </h6>
              </div>
              <div className="pb-5 pt-5 pl-5 pr-5 chart-section">
                {referralSourceSections && referralSourceSections.section1 && (
                  <>
                    <div className="app-chart-container">
                      <div className="inner-container">
                        {referralSourceSections.section1.chart.series.length > 0 ? (
                          <Chart
                            id="new-5"
                            options={referralSourceSections.section1.chart.options}
                            series={referralSourceSections.section1.chart.series}
                            type="donut"
                            height={240}
                          />
                        ) : (
                          <span className="my-auto">0</span>
                        )}
                      </div>
                    </div>
                    <ul className="donut-label list-unstyled">
                      {referralSourceSections.section1.chart.doctorList &&
                        referralSourceSections.section1.chart.doctorList}
                    </ul>
                  </>
                )}
                <div className={!showReferralSourceSectionLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
            <div className="paients-chart-col referral-chart">
              <div className="">
                <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                  Production by Referral Source
                </h6>
              </div>
              <div className="pb-5 pt-5 pl-5 pr-5 chart-section">
                {referralSourceSections && referralSourceSections.section2 && (
                  <>
                    <div className="app-chart-container">
                      <div className="inner-container">
                        {referralSourceSections.section2.chart.series.length > 0 ? (
                          <Chart
                            id="new-5"
                            options={referralSourceSections.section2.chart.options}
                            series={referralSourceSections.section2.chart.series}
                            type="donut"
                            height={240}
                          />
                        ) : (
                          <span className="my-auto">0</span>
                        )}
                      </div>
                    </div>
                    <ul className="donut-label list-unstyled">
                      {referralSourceSections.section2.chart.doctorList &&
                        referralSourceSections.section2.chart.doctorList}
                    </ul>
                  </>
                )}
                <Goal
                  show={GoalShow}
                  AccountId={dashboardSummaryModel.AccountId}
                  DoctorId={dashboardSummaryModel.UserId}
                  selectedGoal={selectedGoal}
                  widgetId={widgetId}
                  reload={() => reload()}
                  onHide={() => SetGoal(false)}
                />
                <DashboardModel
                  appPageLoader={appPageLoader}
                  setAppPageLoader={(pageLoaderValue) => setAppPageLoader(pageLoaderValue)}
                  show={DashboardModal}
                  selectedWidget={selectedWidget}
                  dashboardSummaryModel={dashboardSummaryModel}
                  onHide={() => setDashboardModal(false)}
                />

                <div className={!showReferralSourceSectionLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end collection */}
      {appPageLoader && <AppPageLoader />}
    </div>
  );
}

export default Dashboard_historical;
