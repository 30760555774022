import React, { useState, useEffect } from "react";

import { Modal, ModalBody } from "reactstrap";
import Home from "../../Home";
import { goToSignin } from "../../config/commonFun";

import { ProductName, publicUrl } from "../../config/appConstatnts";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Thankyou from "./Thankyou";

const SignInPage1 = (props) => {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(modal);

  const [step, setStep] = useState(1);

  const [specialty, setSpecialty] = useState([
    {
      Id: 0,
      Description: "",
    },
  ]);

  const [option, setOption] = useState({
    id: 0,
    name: "",
  });

  useEffect(() => {
    let ops = [];
    for (let i = 0; i < specialty.length; i++) {
      let obj = { id: 0, name: "" };
      obj.id = specialty[i].Id;
      obj.name = specialty[i].Description;
      ops.push(obj);
    }
    setOption(ops);
  }, [specialty]);

  const [apiData, setApiData] = useState(null);
  useEffect(() => {
    let data =
      JSON.parse(localStorage.getItem("CompanyDetails")) !== null ||
      JSON.parse(localStorage.getItem("CompanyDetails")) !== undefined
        ? JSON.parse(localStorage.getItem("CompanyDetails"))
        : ProductName;
    setApiData(data);
  }, []);

  // ==============================new implementation ================================
  const defaulteUser = {
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConPassword: "",
    IntegrationId: 0,
    Address: {
      LocationName: "",
      StreetAddress: "",
      City: "",
      State: "",
      ZipCode: "",
    },
    PracticeName: "",
    Speciality: "",
    OtherPraticeManagement: "",
  };
  const postDataChange = (data) => {
    setCommonUserModle({ ...commonUserModle, ...data });
  };
  const [commonUserModle, setCommonUserModle] = useState(defaulteUser);

  const nextPage = () => setStep(step + 1);
  const previousPage = () => setStep(step - 1);

  return (
    <section className="common-section vh-0 login-comman-sec">
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered  sign-up-modal">
        <ModalBody className="main-block signin-block signup1">
          {step < 4 && (
            <div className="login sub-block">
              <div className="row justify-content-center">
                <div className="col-5 bg-green bg-green-1 d-flex">
                  <div className="justify-content-center align-self-center text-center">
                    {apiData && (
                      <img
                        alt="Company Logo"
                        src={`${publicUrl}/${apiData.CompanyId}/images/logo-white.svg`}
                        className="img-fluid logo-white"
                      />
                    )}
                    <h1>Welcome Back!</h1>
                    <p>
                      Increase profits with our proven dental marketing strategy that focuses on
                      patient communications.
                    </p>

                    <div className="btn-block signup-white-btn">
                      <button
                        type="button"
                        className="btn btn-outline-primary blue-btn-bg "
                        onClick={goToSignin}
                      >
                        sign in
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 pl-lg-0">
                  <div className="d-block">
                    {step === 1 ? (
                      <Step1
                        nextPage={nextPage}
                        postData={commonUserModle}
                        setPostData={postDataChange}
                        apiData={apiData}
                      />
                    ) : step === 2 ? (
                      <Step2
                        nextPage={nextPage}
                        postData={commonUserModle}
                        setPostData={postDataChange}
                        apiData={apiData}
                        previousPage={previousPage}
                      />
                    ) : step === 3 ? (
                      <Step3
                        nextPage={nextPage}
                        postData={commonUserModle}
                        setPostData={postDataChange}
                        apiData={apiData}
                        previousPage={previousPage}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 4 && <Thankyou />}
        </ModalBody>
      </Modal>
      <Home />
    </section>
  );
};
export default SignInPage1;
