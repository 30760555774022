import React from "react";

const CustomeDatePicker = ({
  toDate,
  toDateError,
  fromDate,
  fromDateError,
  handelDateChange,
  handelApplyDateFilter,
}) => {
  return (
    <div className="d-flex  py-4  justify-content-center  align-items-end border-top flex-wrap date-filter">
      <div className="filter-ctrl">
        <label>From Date</label>
        <input
          type="date"
          className="form-control date-select"
          name="fromDate"
          value={fromDate}
          max={new Date().toISOString().slice(0, 10)}
          onChange={handelDateChange}
        />
        <div className="err-message">{fromDateError}</div>
      </div>

      <div className="filter-ctrl">
        <label>To Date</label>
        <input
          type="date"
          className="form-control date-select"
          name="toDate"
          value={toDate}
          onChange={handelDateChange}
          max={new Date().toISOString().slice(0, 10)}
        />
        <div className="err-message">{toDateError}</div>
      </div>
      <button
        type="button"
        className="btn btn-sm blue-btn-bg green-btn green-border "
        onClick={handelApplyDateFilter}
        disabled={fromDateError.length !== 0 || toDateError.length !== 0}
      >
        Apply
      </button>
    </div>
  );
};

export default CustomeDatePicker;
