import React from "react";

export default function ErrorPage() {
  return (
    <>
      <div className="mx-auto">
        <h2>Page Not Found</h2>
      </div>
    </>
  );
}
