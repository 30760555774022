import React, { useLayoutEffect } from "react";

import { Router, Route, Switch, Redirect } from "react-router-dom";

import Home from "./Home";
import SignInPage from "./auth-pages/SignInPage";

import ChangePassPage from "./auth-pages/ChangePassPage";
import ResetPasswordPage from "./auth-pages/ResetPasswordPage";
import ForgotPasswordPage from "./auth-pages/ForgotPasswordPage";
import SignUpPage from "./auth-pages/SignUpPage/index";

import AccountSetup from "./auth-pages/AccountSetup";

import { ToastContainer } from "react-toastify";

import Api from "./API/login";

import "react-toastify/dist/ReactToastify.min.css";

import history from "./history";
import {
  domain,
  localStorageCompanyDetails,
  localStorageCompanyId,
  ProductName,
  publicUrl,
  url_link,
} from "./config/appConstatnts";
import ErrorPage from "./auth-pages/ErrorPage";

function App() {
  const [appLoaderState, setappLoaderState] = React.useState(true);

  const appThemeColor = (url, companyId) => {
    document.getElementById("favicon").href = `${url}/${companyId}/images/Favicon.png`;

    document.getElementById("custom-style").href = `${url}/${companyId}/css/style.css`;

    document.getElementById(
      "custom-responsive-style"
    ).href = `${url}/${companyId}/css/responsive.css`;

    document.getElementById(
      "custom-responsive-setting-style"
    ).href = `${url}/${companyId}/css/responsive-settings.css`;
  };

  useLayoutEffect(() => {
    if (
      JSON.parse(localStorage.getItem("CompanyId")) === null ||
      localStorageCompanyDetails === null ||
      (localStorageCompanyDetails && domain !== localStorageCompanyDetails.DomainName)
    ) {
      Api.getCompanyDetail(domain)
        .then((res) => {
          localStorage.setItem("CompanyId", res.data.Result.CompanyId);
          localStorage.setItem("CompanyDetails", JSON.stringify(res.data.Result));
          document.title = res.data.Result.CompanyName;
          appThemeColor(url_link, res.data.Result.CompanyId);
          document.querySelector("#custom-style").onload = () => {
            setappLoaderState(false);
          };
          return res.data.CompanId;
        })
        .catch((error) => {
          setappLoaderState(false);
          return error;
        });
    } else {
      document.title = ProductName.CompanyName;
      appThemeColor(publicUrl, localStorageCompanyId);
      document.querySelector("#custom-style").onload = () => {
        setappLoaderState(false);
      };
    }
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") != null && localStorage.getItem("token").length > 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );

  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") == null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );

  const Loading = () => (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          height: "100%",
          width: "100%",
          top: "0",
          left: "0",
          backgroundColor: "rgba(0, 0, 0, 0.37)",
          zIndex: "9999",
        }}
      >
        <div>
          <div
            style={{
              width: "70px",
              height: "70px",
              border: "solid 5px transparent",
              borderTopColor: "#00c8b1 !important",
              borderLeftColor: "#00c8b1 !important",
            }}
          >
            <h5>Loading...</h5>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {appLoaderState && <Loading />}
      {!appLoaderState && (
        <div className="App">
          <Router history={history}>
            <Switch>
              <PublicRoute path="/" component={SignInPage} exact />
              <PublicRoute path="/signin" component={SignInPage} exact />
              <PrivateRoute path="/dashboard" component={Home} exact />
              <PublicRoute path="/signup" component={SignUpPage} exact />
              <PublicRoute path="/change-password" component={ChangePassPage} exact />
              <PublicRoute path="/reset-password" component={ResetPasswordPage} exact />
              <PublicRoute path="/forgot-password" component={ForgotPasswordPage} exact />
              <PublicRoute path="/activation" component={AccountSetup} exact />
              <Route component={ErrorPage} />
            </Switch>
          </Router>
          <ToastContainer hideProgressBar />
        </div>
      )}
    </>
  );
}

export default App;
