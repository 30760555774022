import React from "react";
import { Modal } from "react-bootstrap";
import Eye from "../assets/images/eye.png";

function Title_7(props) {
  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model sampleRequest-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div class="row ">
              <div class=" col-12 text-center pb-2">
                <p class="gray-txt">
                  Your new password must be different from old passwords.
                </p>
              </div>
              <div class=" col-12 ">
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control light-border pass-input"
                    id="exampleInputPassword1"
                    placeholder="Enter Old Password*"
                  />
                  <span class="input-icon">
                    <img src={Eye} alt="views" class="img-fluid" />
                  </span>
                </div>
              </div>
              <div class=" col-12 ">
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control light-border pass-input"
                    id="exampleInputPassword1"
                    placeholder="Enter New Password*"
                  />
                  <span class="input-icon">
                    <img src={Eye} alt="views" class="img-fluid" />
                  </span>
                </div>
              </div>
              <div class=" col-12 ">
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control light-border pass-input"
                    id="exampleInputPassword1"
                    placeholder="Enter Confirm Password*"
                  />
                  <span class="input-icon">
                    <img src={Eye} alt="views" class="img-fluid" />
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-outline-primary blue-btn-bg "
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Title_7;
