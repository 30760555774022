import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";

// Main Api Class for signup functionality
class Api {
  // Api for check an email.
  static getCheckEmail = (email) => {
    return axios.get(
      API_URL + "/Verident/CheckEmailExists?Email=" + email,
      HEADER
    );
  };

  // Api for get state of US
  static getState = (CountryID) => {
    return axios.get(API_URL + "/State/Get/" + CountryID);
  };

  // Api for Signup
  static signUp = (payload) => {
    return axios.post(API_URL + "/Verident/SignUp", payload, HEADER);
  };

  // Get Specialities
  static getSpecialities = () => {
    return axios.get(
      API_URL + "/Verident/GetSpecialities?IsSignUP=True",
      HEADER
    );
  };

  static getIntegrationList = () => {
    return axios.get(API_URL + "/Integrationlist", HEADER);
  };
}

export default Api;
