import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import Home from "../Home";
import { Modal, ModalBody } from "reactstrap";

import { validateForm } from "../config/commonFun";
import Api from "../API/login";
import { ProductName, publicUrl } from "../config/appConstatnts";
import AppSectionLoader from "../common-components/AppSectionLoader";

const SignInPage = (props) => {
  const [userModel, setUserModel] = useState({
    Username: "",
    Password: "",
  });

  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });

  const [textBoxType, setTextBoxType] = useState("password");

  const [loader, setLoader] = useState(false);

  const [resMessage, setResMessage] = useState("");
  const [apiLoader, setApiLoader] = useState(false);
  const [apiData, setApiData] = useState(null);

  let history = useHistory();
  const redirectToSignUp = () => {
    history.push("/signup");
  };

  const [eyeTog3, seteyeTog3] = useState("false");

  const handleToggleEye = () => {
    textBoxType === "password" ? setTextBoxType("text") : setTextBoxType("password");
    seteyeTog3(!eyeTog3);
  };

  const [modal, setModal] = useState(true);

  const toggle = () => setModal(modal);

  const handelChange = (event) => {
    let { name, value } = event.target;
    let errorValue = "";

    if (name !== "Password") {
      value = value.trim();
    }
    setUserModel((userModel) => ({
      ...userModel,
      [name]: value,
    }));

    switch (name) {
      case "Username":
        if (value.length === 0) {
          errorValue = "Email or Username required.";
        }
        break;

      case "Password":
        if (value.length === 0) {
          errorValue = "Password is required.";
        }
        break;

      default:
        break;
    }

    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;

    if (userModel.Username.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Username: "Email or Username required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Password: "Password is required.",
      }));
      flagError = true;
    }

    if (validateForm(errors) && !flagError) {
      setLoader(true);
      Api.logIn(userModel)
        .then((res) => {
          setLoader(false);
          localStorage.setItem("token", res.data.Result.Token);
          localStorage.setItem("userData", JSON.stringify(res.data.Result));
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(JSON.parse(error.request.response).Message);
        });
    }
  };

  useEffect(() => {
    if (!!localStorage.getItem("token") && localStorage.getItem("token").length > 0) {
      useHistory.push("/dashboard");
    }
    let data =
      JSON.parse(localStorage.getItem("CompanyDetails")) !== null ||
      JSON.parse(localStorage.getItem("CompanyDetails")) !== undefined
        ? JSON.parse(localStorage.getItem("CompanyDetails"))
        : ProductName;
    setApiData(data);
    setApiLoader(true);
  }, []);

  return (
    <section className="common-section vh-0 login-comman-sec">
      {!apiLoader && <AppSectionLoader />}
      {apiLoader && (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered sign-up-modal">
          <ModalBody className="main-block signin-block">
            <div className="login sub-block">
              <div className="row justify-content-center">
                <div className="col-lg-7 pr-lg-0">
                  <div className="d-block">
                    <div className="inner-block text-center">
                      <div className="block-1">
                        <h1>
                          Sign in to{" "}
                          <span>
                            <b>{apiData && apiData.CompanyName}</b>
                          </span>
                        </h1>
                        <p>Welcome back! Enter your credentials to proceed </p>
                      </div>

                      <form onSubmit={handelSubmit}>
                        <div className="content">
                          {resMessage !== "" && (
                            <div className="err-message main-err-message">{resMessage}</div>
                          )}
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control light-border"
                              id="username"
                              placeholder="User Name / Email Address*"
                              maxLength="100"
                              name="Username"
                              value={userModel.Username}
                              onChange={handelChange}
                            />
                            <span className="input-icon1">
                              {apiData && (
                                <img
                                  src={`${publicUrl}/${apiData.CompanyId}/images/envelope.svg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              )}
                            </span>

                            {errors.Username.length > 0 && (
                              <div className="err-message">{errors.Username}</div>
                            )}
                          </div>
                          <div className="form-group">
                            <span className="input-icon1">
                              {apiData && (
                                <img
                                  src={`${publicUrl}/${apiData.CompanyId}/images/padlock.svg`}
                                  alt=""
                                  className="img-fluid"
                                />
                              )}
                            </span>

                            <input
                              type={textBoxType}
                              className="form-control light-border pass-input"
                              id="exampleInputPassword1"
                              placeholder="Password*"
                              name="Password"
                              value={userModel.Password}
                              onChange={handelChange}
                            />

                            <span className="input-icon">
                              <i
                                className={`passTogEye icon-eye ${!eyeTog3 ? " passVisible" : " "}`}
                                onClick={handleToggleEye}
                              ></i>
                            </span>

                            {errors.Password.length > 0 && (
                              <div className="err-message">{errors.Password}</div>
                            )}
                          </div>
                          <div className="forget-link  link-purple">
                            <Link to="/forgot-password" className="h5 f-600">
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                        <div className="account-btn-block">
                          <div className="btn-block">
                            <button
                              type="submit"
                              className={`btn btn-outline-primary blue-btn-bg ${
                                loader ? "btn-loading-state" : ""
                              }`}
                            >
                              {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                              sign in
                            </button>
                          </div>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Don't have Account ?
                            <Link
                              to={"/signup"}
                              className="h5 f-600 pl-2"
                              onClick={redirectToSignUp}
                            >
                              Sign Up
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-5 bg-green d-flex">
                  <div className="justify-content-center align-self-center text-center">
                    {apiData && (
                      <img
                        src={`${publicUrl}/${apiData.CompanyId}/images/logo-white.svg`}
                        alt="Company Logo"
                        className="img-fluid logo-white"
                      />
                    )}
                    <h1>Welcome</h1>
                    <p>
                      Increase profits with our proven dental marketing strategy that focuses on
                      patient communications.
                    </p>
                    <div className="btn-block signup-white-btn">
                      <button
                        type="button"
                        className="btn btn-outline-primary blue-btn-bg "
                        onClick={redirectToSignUp}
                      >
                        sign up
                      </button>
                    </div>
                    <p>Don't have an account?</p>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      <Home />
    </section>
  );
};
export default SignInPage;
