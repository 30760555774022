import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";

import Home from "../Home";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { validateForm } from "../config/commonFun";
import { validPasswordRegex } from "../config/appRegex";

import Api from "../API/login";
import { publicUrl } from "../config/appConstatnts";

const ResetPasswordPage = (props) => {
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(modal);

  const [eyeTog2, seteyeTog2] = useState("false");
  const handleToggleeyeTog2 = () => {
    passwordTextBoxType === "password"
      ? setPasswordTextBoxType("text")
      : setPasswordTextBoxType("password");
    seteyeTog2(!eyeTog2);
  };

  const [eyeTog3, seteyeTog3] = useState("false");
  const handleToggleeyeTog3 = () => {
    conPasswordTextBoxType === "password"
      ? setConPasswordTextBoxType("text")
      : setConPasswordTextBoxType("password");
    seteyeTog3(!eyeTog3);
  };

  let history = useHistory();

  const redirectToSignin = () => {
    history.push("/signin");
  };

  const [loader, setLoader] = useState(false);

  const [rMessage, setRMessage] = useState("");

  const [passwordTextBoxType, setPasswordTextBoxType] = useState("password");

  const [conPasswordTextBoxType, setConPasswordTextBoxType] = useState("password");

  const [resetPasswordModel, setResetPasswordModel] = useState({
    Password: "",
    ConPassword: "",
    Email: "",
  });

  const [resetPasswordErrorModel, setResetPasswordErrorModel] = useState({
    Password: "",
    ConPassword: "",
  });

  useEffect(() => {
    const qString = queryString.parse(window.location.search);
    if (qString != null && !!qString.Eamil) {
      setResetPasswordModel((resetPasswordModel) => ({
        ...resetPasswordModel,
        Email: qString.Eamil,
      }));
    }
  }, []);

  const handelChanges = (event) => {
    event.preventDefault();
    let { name, value } = event.target;

    setResetPasswordModel((resetPasswordModel) => ({
      ...resetPasswordModel,
      [name]: value,
    }));

    let errorMsg = "";
    switch (name) {
      case "Password":
        if (value.length === 0) {
          errorMsg = "New Password is required.";
        } else {
          errorMsg = validPasswordRegex.test(value) ? "" : "Enter valid Password.";
        }
        break;
      case "ConPassword":
        if (value.length === 0) {
          errorMsg = "Confirm New Password is required.";
        } else if (resetPasswordModel.Password !== value) {
          errorMsg = "Password don't match.";
        }
        break;
      default:
        break;
    }

    setResetPasswordErrorModel((resetPasswordErrorModel) => ({
      ...resetPasswordErrorModel,
      [name]: errorMsg,
    }));
  };

  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;
    if (resetPasswordModel.Password.length === 0) {
      setResetPasswordErrorModel((resetPasswordErrorModel) => ({
        ...resetPasswordErrorModel,
        Password: "New Password required.",
      }));
      flagError = true;
    }
    if (resetPasswordModel.ConPassword.length === 0) {
      setResetPasswordErrorModel((resetPasswordErrorModel) => ({
        ...resetPasswordErrorModel,
        ConPassword: "Confirm Password required.",
      }));
      flagError = true;
    }
    if (validateForm(resetPasswordErrorModel) && !flagError) {
      setLoader(true);
      Api.resetPassword(resetPasswordModel)
        .then((res) => {
          setLoader(false);
          setRMessage(res.data.Message);
          setTimeout(() => redirectToSignin(), 5000);
        })
        .catch((error) => {
          setLoader(false);
          setRMessage(JSON.parse(error.request.response).Message);
        });
    }
  };

  return (
    <section className="common-section vh-0 login-comman-sec">
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered  sign-up-modal">
        <ModalBody className="main-block signin-block">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Reset Password</h1>
                      <p className="mb-2">
                        Your new password must be different from old passwords.
                      </p>
                    </div>
                    <div className="err-message">{rMessage}</div>
                    <form onSubmit={handelSubmit}>
                      <div className="content">
                        <div className="form-group">
                          <span className="input-icon1">
                            {JSON.parse(localStorage.getItem("CompanyDetails")) && (
                              <img
                                src={`${publicUrl}/${
                                  JSON.parse(localStorage.getItem("CompanyDetails")).CompanyId
                                }/images/icon-2.png`}
                                alt="views"
                                className="img-fluid"
                              />
                            )}
                          </span>
                          <input
                            type={passwordTextBoxType}
                            className="form-control light-border pass-input"
                            id="Password"
                            placeholder="New Password*"
                            name="Password"
                            value={resetPasswordModel.Password}
                            onChange={handelChanges}
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog2 ? "passVisible" : ""}`}
                              onClick={handleToggleeyeTog2}
                            ></i>
                          </span>
                        </div>
                        <div className="err-message">{resetPasswordErrorModel.Password}</div>

                        <div className="form-group">
                          <span className="input-icon1">
                            {JSON.parse(localStorage.getItem("CompanyDetails")) && (
                              <img
                                src={`${publicUrl}/${
                                  JSON.parse(localStorage.getItem("CompanyDetails")).CompanyId
                                }/images/icon-2.png`}
                                alt="views"
                                className="img-fluid"
                              />
                            )}
                          </span>
                          <input
                            type={conPasswordTextBoxType}
                            className="form-control light-border pass-input"
                            id="ConPassword"
                            placeholder="Confirm New Password*"
                            name="ConPassword"
                            value={resetPasswordModel.ConPassword}
                            onChange={handelChanges}
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog3 ? "passVisible" : ""}`}
                              onClick={handleToggleeyeTog3}
                            ></i>
                          </span>
                        </div>
                        <div className="err-message">{resetPasswordErrorModel.ConPassword}</div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="submit"
                            className={`btn btn-outline-primary blue-btn-bg ${
                              loader ? "btn-loading-state" : ""
                            }`}
                          >
                            <div className="button-inner">
                              {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                              <span className="btn-text">Save</span>
                            </div>
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <Link to="/signin" className="h5 f-600 pl-2">
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  {JSON.parse(localStorage.getItem("CompanyDetails")) && (
                    <img
                      src={`${publicUrl}/${
                        JSON.parse(localStorage.getItem("CompanyDetails")).CompanyId
                      }/images/logo-white.svg`}
                      className="img-fluid logo-white"
                      alt="companyLogo-white"
                    />
                  )}
                  <h1>Welcome</h1>
                  <p>
                    Increase profits with our proven dental marketing strategy that focuses on
                    patient communications.
                  </p>
                  <div className="btn-block signup-white-btn">
                    <button
                      type="button"
                      className="btn btn-outline-primary blue-btn-bg "
                      onClick={redirectToSignin}
                    >
                      sign in
                    </button>
                  </div>
                  <p>Remember your password?</p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Home />
    </section>
  );
};
export default ResetPasswordPage;
