// Declare all application const.
// ===================localurl===========
// export const RecordLincURL = "http://localhost:2298/";
// export const RecordLincURL = "http://localhost:3000";
// export const API_URL = "http://localhost:56697/api";
// ===================liveurl===========
export const API_URL = "https://recordlinc.com/rlapi/api";
export const RecordLincURL = "https://recordlinc.com/";

export const IntigrationAuthKey = "https://integrate.drdds.com/authKey/";
export const ProductName =
  localStorage.getItem("CompanyDetails") === undefined ||
  localStorage.getItem("CompanyDetails") == null
    ? ""
    : JSON.parse(localStorage.getItem("CompanyDetails"));

export const domain = window.location.hostname;
// export const domain = "fly.drdds.com";
// export const domain = "hi5.drdds.com";
// export const domain = "scout.drdds.com";
// export const domain = "wahi.drdds.com";
// export const domain = "a.drdds.com";
// export const domain = "kasper.drdds.com";
// export const domain = "one.drdds.com";
// export const domain = "dn.drdds.com";
// export const domain = "crimson.drdds.com";
// export const domain = "marketing32.drdds.com";

export const publicUrl = process.env.PUBLIC_URL; //live

export const url_link = window.location.origin; //live

// Set comman Header value for API calls
export const HEADER = {
  headers: {
    "Content-Type": "application/json",
    Company_Id: localStorage.getItem("CompanyId"),
    access_token: localStorage.getItem("token"),
  },
};

export const HEADERMultipart = {
  headers: {
    access_token: localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
    Company_Id: localStorage.getItem("CompanyId"),
  },
};

export const localStorageCompanyId = JSON.parse(localStorage.getItem("CompanyId"));
export const localStorageCompanyDetails = JSON.parse(localStorage.getItem("CompanyDetails"));
export const localStorageUserData = JSON.parse(localStorage.getItem("userData"));
export const localStorageSectionList = JSON.parse(localStorage.getItem("sectionList"));
export const localStorageToken = localStorage.getItem("token");
